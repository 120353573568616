.quantitySelector {
  display: flex;
  align-items: center;

  .button {
    @extend %txt-title-md;
    line-height: 0;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }

    height: 26px;
    width: 26px;
    border-radius: 50%;
    color: $gray-125;
    border: none;
    padding: 0;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    &:disabled, &[aria-disabled="true"] {
      color: $text-color-disabled;
      opacity: 0.5;
      cursor: default;
    }
  }

  .quantity {
    @extend %txt-title-sm;
    width: 40px;
    height: 24px;
    background-color: $ds-background-primary;
    color: $ds-text-on-bg-primary;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
